<template>
  <div>
    <div class="row">
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          v-loading="loading"
          :title="
            Number(total_deposit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ยอดฝากทั้งหมด"
          class="mb-2"
        >
          <div slot="header" class="d-none d-md-block icon-success">
            <i class="nc-icon nc-money-coins text-success"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          v-loading="loading"
          :title="
            Number(total_withdraw).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ยอดถอนทั้งหมด"
          class="mb-2"
        >
          <div slot="header" class="d-none d-md-block icon-danger">
            <i class="nc-icon nc-money-coins text-danger"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          v-loading="loading"
          :title="
            Number(total_bonus).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="ฝากโบนัส"
          class="mb-2"
        >
          <div slot="header" class="d-none d-md-block icon-info">
            <i class="nc-icon nc-money-coins text-info"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          v-loading="loading"
          :title="
            Number(total_lose).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="คืนยอดเสีย"
          class="mb-2"
        >
          <div slot="header" class="d-none d-md-block icon-warning">
            <i class="nc-icon nc-money-coins text-warning"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          v-loading="loading"
          :title="
            Number(total_income).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          "
          subTitle="กำไร/ขาดทุน"
          class="mb-2"
        >
          <div slot="header" class="d-none d-md-block icon-success">
            <i class="nc-icon nc-money-coins text-success"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          v-loading="loading"
          :title="Number(active_today).toLocaleString()"
          subTitle="สมาชิกใช้งานวันนี้"
          class="mb-2"
        >
          <div slot="header" class="d-none d-md-block icon-info">
            <i class="nc-icon nc-single-02 text-info"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          v-loading="loading"
          :title="Number(regis_deposit).toLocaleString()"
          subTitle="สมาชิกใหม่ฝากเงิน"
          class="mb-2"
        >
          <div slot="header" class="d-none d-md-block icon-success">
            <i class="nc-icon nc-single-02 text-success"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3">
        <stats-card
          v-loading="loading"
          :title="Number(member_regis).toLocaleString()"
          subTitle="ลูกค้าสมัครวันนี้"
          class="mb-2"
        >
          <div slot="header" class="d-none d-md-block icon-warning">
            <i class="nc-icon nc-single-02 text-success"></i>
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <h4 class="m-0">รายงานสรุปภาพรวม</h4>
          </div>
          <div>
            <div class="row">
              <div class="col-sm-4 col-md-3 d-inline-block">
                <div class="d-flex mb-2">
                  <span class="my-auto mr-2" style="width: 30px">เริ่ม</span>
                  <fg-input class="m-0 w-100">
                    <el-date-picker
                      v-model="start"
                      type="date"
                      placeholder="วันที่เริ่ม"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      @change="changeDate()"
                    >
                    </el-date-picker>
                  </fg-input>
                </div>
              </div>
              <div class="col-sm-4 col-md-3 d-inline-block">
                <div class="d-flex mb-2">
                  <span class="my-auto mr-2" style="width: 30px">ถึง</span>
                  <fg-input class="m-0 w-100">
                    <el-date-picker
                      v-model="end"
                      type="date"
                      placeholder="ถึง"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      @change="changeDate()"
                    >
                    </el-date-picker>
                  </fg-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <el-table
                  stripe
                  style="width: 100%; text-align: right"
                  :data="tableData"
                  border
                  v-loading="loading"
                >
                  <el-table-column :min-width="100" label="วันที่">
                    <template v-slot="props">
                      <div class="text-center">
                        <span v-html="props ? props.row.date : ''"> </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="ฝาก">
                    <template v-slot="props">
                      <span
                        class="text-success"
                        v-html="
                          props
                            ? Number(props.row.deposit).toLocaleString()
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="ถอน">
                    <template v-slot="props">
                      <span
                        class="text-danger"
                        v-html="
                          props
                            ? Number(props.row.withdraw).toLocaleString()
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="รอถอน">
                    <template v-slot="props">
                      <span
                        class="text-warning"
                        v-html="
                          props
                            ? Number(props.row.waitwithdraw).toLocaleString()
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="HengPay">
                    <template v-slot="props">
                      <span
                        v-html="
                          props
                            ? Number(props.row.Hengpay).toLocaleString()
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="PomPay">
                    <template v-slot="props">
                      <span
                        v-html="
                          props ? Number(props.row.Pompay).toLocaleString() : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="โบนัส">
                    <template v-slot="props">
                      <span
                        class="text-info"
                        v-html="
                          props ? Number(props.row.bonus).toLocaleString() : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="ยอดเสีย">
                    <template v-slot="props">
                      <span
                        v-html="
                          props
                            ? Number(props.row.cashback).toLocaleString()
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="กำไร">
                    <template v-slot="props">
                      <span
                        :class="
                          props.row.profit > 0 ? 'text-success' : 'text-danger'
                        "
                        v-html="
                          props ? Number(props.row.profit).toLocaleString() : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="Ag w/l">
                    <template v-slot="props">
                      <span
                        v-html="
                          props ? Number(props.row.Agwl).toLocaleString() : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="ยอดดิฟ">
                    <template v-slot="props">
                      <span
                        v-html="
                          props ? Number(props.row.diff).toLocaleString() : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="100" label="สมาชิกใช้งาน">
                    <template v-slot="props">
                      <span
                        v-html="
                          props
                            ? Number(props.row.Activemember).toLocaleString()
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="120" label="สมาชิกสมัครใหม่">
                    <template v-slot="props">
                      <span
                        v-html="
                          props
                            ? Number(props.row.Newmember).toLocaleString()
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="120" label="สมาชิกใหม่ฝาก">
                    <template v-slot="props">
                      <span
                        v-html="
                          props
                            ? Number(
                                props.row.Newmemberdeposit
                              ).toLocaleString()
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :min-width="120"
                    label="อัตราการสมัครแล้วเติม(%)"
                  >
                    <template v-slot="props">
                      <span
                        v-html="
                          props
                            ? Number(props.row.regisDepositPercent)
                                .toFixed(2)
                                .toLocaleString() + '%'
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :min-width="120"
                    label="ยอดฝากแรกของ(สมัครแล้วเติม)"
                  >
                    <template v-slot="props">
                      <span
                        v-html="
                          props
                            ? Number(props.row.Newmembersum).toLocaleString()
                            : ''
                        "
                      ></span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker, Select, Table, TableColumn, Button } from "element-ui";
import moment from "moment";
import { StatsCard, FormGroupInput as FgInput } from "src/components/index";
import axios from "axios";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    StatsCard,
  },

  data() {
    return {
      loading: false,

      start: "",
      end: "",

      active_today: 0,
      regis_deposit: 0,
      total_bonus: 0,
      total_deposit: 0,
      total_income: 0,
      total_lose: 0,
      total_withdraw: 0,
      member_regis: 0,
      total_Agwl: 0,
      total_waitwithdraw: 0,
      total_diff: 0,
      total_hengpay: 0,
      total_pompay: 0,

      sumNewmembersum: 0,

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableData: [],
      fuseSearch: null,

      timeTransactions: null,
    };
  },

  mounted() {
    this.start = moment().startOf("month").format("YYYY-MM-DD");
    this.end = moment().format("YYYY-MM-DD");

    this.changeDate();
  },
  methods: {
    changeDate() {
      if (this.start && this.end) {
        this.getReport();
        // this.getDashboard();
        // this.getRegisDeposit();
      }
    },
    async getReport() {
      this.loading = true;

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostnametuktuk888}/report_dashboard?start=${this.start}&end=${this.end}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };
      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = data.res_result;
            this.pagination.total = data.res_result.length;

            this.total_deposit = 0;
            this.total_withdraw = 0;
            this.total_bonus = 0;
            this.total_lose = 0;
            this.active_today = 0;
            this.member_regis = 0;
            this.regis_deposit = 0;
            this.total_income = 0;
            this.total_Agwl = 0;
            this.total_waitwithdraw = 0;
            this.total_diff = 0;
            this.total_hengpay = 0;
            this.total_pompay = 0;

            for (const key in this.tableData) {
              if (Object.hasOwnProperty.call(this.tableData, key)) {
                const el = this.tableData[key];
                this.total_deposit = this.total_deposit + el.deposit;
                this.total_withdraw = this.total_withdraw + el.withdraw;
                this.total_bonus = this.total_bonus + el.bonus;
                this.total_lose = this.total_lose + el.cashback;
                this.active_today = this.active_today + el.Activemember;
                this.member_regis = this.member_regis + el.Newmember;
                this.regis_deposit = this.regis_deposit + el.Newmemberdeposit;
                this.total_Agwl = this.total_Agwl + el.Agwl;
                this.total_waitwithdraw =
                  this.total_waitwithdraw + el.waitwithdraw;
                this.total_diff = this.total_diff + el.diff;
                this.sumNewmembersum = this.sumNewmembersum + el.Newmembersum;
                this.total_hengpay = this.total_hengpay + el.Hengpay;
                this.total_pompay = this.total_pompay + el.Pompay;
              }
            }

            this.active_today = Math.ceil(
              this.active_today / this.tableData.length
            );

            this.total_income =
              this.total_deposit -
              this.total_withdraw +
              this.total_bonus +
              this.total_lose;

            const summary = {
              date: "Total",
              Activemember: this.active_today,
              Agwl: this.total_Agwl,
              Newmember: this.member_regis,
              bonus: this.total_bonus,
              cashback: this.total_lose,
              deposit: this.total_deposit,
              withdraw: this.total_withdraw,
              profit: this.total_income,
              waitwithdraw: this.total_waitwithdraw,
              diff: this.total_diff,
              Newmemberdeposit: this.regis_deposit,
              regisDepositPercent:
                (this.regis_deposit * 100) / this.member_regis,
              Newmembersum: this.sumNewmembersum,
              Hengpay: this.total_hengpay,
              Pompay: this.total_pompay,
            };
            this.tableData.push(summary);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    // async getDashboard() {
    //   if (this.start === null) {
    //     this.start = "";
    //   }
    //   if (this.end === null) {
    //     this.end = "";
    //   }
    //   let config = {
    //     method: "post",
    //     maxBodyLength: Infinity,
    //     url: `${this.$hostnametuktuk888}/countdashboard`,
    //     headers: {
    //       authorization: this.$cookies.get("token"),
    //     },
    //     data: {
    //       start: this.start,
    //       end: this.end,
    //     },
    //   };

    //   this.loading = true;

    //   await axios
    //     .request(config)
    //     .then((res) => {
    //       this.loading = false;
    //       const data = res.data.res_result;
    //       this.active_today = data.active_today;
    //       this.total_bonus = data.total_bonus;
    //       this.total_deposit = data.total_deposit;
    //       this.total_lose = data.total_lose;
    //       this.total_withdraw = data.total_withdraw;
    //       this.member_regis = data.member_regis;
    //       this.total_income =
    //         this.total_deposit -
    //         this.total_withdraw -
    //         this.total_bonus -
    //         this.total_lose;
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //     });
    // },

    // async getRegisDeposit() {
    //   let config = {
    //     method: "post",
    //     maxBodyLength: Infinity,
    //     url: `${this.$hostnametuktuk888}/regis_deposit`,
    //     headers: {
    //       authorization: this.$cookies.get("token"),
    //     },
    //     data: {
    //       start: this.start,
    //       end: this.end,
    //     },
    //   };

    //   this.loadingRegisCard = true;

    //   await axios
    //     .request(config)
    //     .then((res) => {
    //       this.loadingRegisCard = false;
    //       let data = res.data;
    //       if (data.res_code === "00") {
    //         this.regis_deposit = data.res_result.regis_deposit;
    //       }
    //     })
    //     .catch((error) => {
    //       this.loadingRegisCard = false;
    //     });
    // },
  },
};
</script>
<style>
.el-table tr:last-child td {
  background-color: #f5f7fa !important;
}
</style>
